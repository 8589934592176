import request from '@/api/request'

export function storeUserGroup(data) {
    return request({
            url: `/usergroups`,
            method: 'post',
            data,
        }
    )
}

export function updateUserGroup(data) {
    return request({
            url: `/usergroups`,
            method: 'put',
            data,
        }
    )
}

export function createUserGroup(data) {
    return request({
            url: `/usergroups`,
            method: 'post',
            data,
        }
    )
}

export function findUserGroup(id) {
    return request({
        url: `/usergroups/${id}`,
        method: 'get'
    })
}

export function getAccessRights() {
    return request({
        url: `/usergroups/accessRights/accessRightsAllowed`,
        method: 'get'
    })
}

export function getAdminPermissions() {
    return request({
        url: `/usergroups/accessRights/permissionsAdminAllowed`,
        method: 'get'
    })
}

export function deleteUserGroup(id) {
    return request({
        url: `/usergroups/${id}`,
        method: 'delete'
    })
}

export function getAvailableMembers() {
    return request({
        url: `/usergroups/availableMembers`,
        method: 'get'
    })
}

export function getPermissions() {
    return request({
        url: `/usergroups/accessRights/permissionsAllowed`,
        method: 'get'
    })
}

export function getUGTree(id) {
    const query = id ? `/${id}` : ''
    return request({
        url: `/showOrg${query}`,
        method: 'get'
    })
}

export function prepareUserGroup(orgId) {
    return request({
            url: `/usergroups/prepare/${orgId}`,
            method: 'get'
        }
    )
}
